
import { Component, Vue } from "vue-property-decorator"
import PageTemplate from "@/components/PageTemplate.vue"

@Component({
  components: {
    PageTemplate
  }
})
export default class About extends Vue {
  created() {
    this.$store.dispatch("config/loadAbout")
  }
  get clientBranch() {
    return this.$store.getters["config/getClientBranch"]
  }
  get clientEnvironment() {
    return this.$store.getters["config/getClientEnvironment"]
  }
  get clientSha() {
    return this.$store.getters["config/getClientSha"]
  }
  get serverBranch() {
    return this.$store.getters["config/getServerBranch"]
  }
  get serverEnvironment() {
    return this.$store.getters["config/getServerEnvironment"]
  }
  get serverSha() {
    return this.$store.getters["config/getServerSha"]
  }
}
